<template>
  <div
    class="item-dialog-delete text-center d-flex align-center justify-center"
    style="height: 100%;"
  >
    <div
      class="headline"
    >Are you sure you want <br /> to delete this document?</div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  name: 'ItemDialogDeleteContent'
}
</script>